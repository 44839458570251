import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    this.updateCheckedState();
  }

  updateCheckedState() {
    const params = new URLSearchParams(window.location.search);
    const selectedBrandIds = params.getAll("brand_ids[]");

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = selectedBrandIds.includes(checkbox.value);
    });
  }

  filter(event) {
    const checkbox = event.target;
    const params = new URLSearchParams(window.location.search);

    if (checkbox.checked) {
      params.append("brand_ids[]", checkbox.value);
    } else {
      params.delete("brand_ids[]", checkbox.value);
    }

    window.location.href = `${window.location.pathname}?${params.toString()}`;
  }
}
