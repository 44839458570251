import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  timeout = null;

  perform() {
    clearTimeout(this.timeout);
    const query = this.element.querySelector("input").value.trim();

    if (query.length > 0 && query.length < 3) return;

    this.timeout = setTimeout(() => {
      this.element.requestSubmit();
    }, 300);
  }
}
